// Here you can add other styles

.bell-icon-custom li a {
    color: #2c3e50 !important;
    text-decoration: none !important;
}
.vehicle-request-job{
    position: relative;
    height: 26px;
    width: 25px;
    display: inline-block;
    border-radius: 50%;
    top: 8px;
}